const RefundPolicy = () => {
    return ( 
        <div className="bg-gray-100 dark:bg-gray-900">
        <div className="container mx-auto px-4 py-8 lg:px-32 xl:px-48 dark:text-gray-400">
          <h1 className="text-3xl font-bold mb-4 dark:text-gray-200 text-center">Cancellation & Refund Policy</h1>
  
          <br />
          <p className="mb-4">
          All sales are final. Because Decodiefy Technologies offers a Expert Advice evaluation to ensure that our products and services will meet your needs without the need to purchase, there will be ABSOLUTELY NO REFUNDS and CANCELLATIONS.
          </p>
  
          <p className="mb-4">
          Before deciding to subscribe to our services, please make sure to take our Expert Advice, the evaluation version that we provide. We do not offer refunds on subscriptions that have already been taken. We so believe in our services and support.
          </p>
        
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">For this reason, we strongly recommend that before payment, our visitors:-</h2>
  
          <p className="mb-4">
          Read all information about our products, services and support given to our clients.
          </p>

          <ul className="list-disc list-inside mb-4">
            <li>
            Evaluate the quality of our services by taking our Expert Advice.
            </li>
            <li>
            Read all About Us and our technical Team.
            </li>
            <li>
            Read our Terms of Use.
            </li>
            <li>
            Read our Privacy Policy.
            </li>
          </ul>

          <p className="mb-4">
          Do not allow children or other unauthorized family members or friends to access your credit cards or your account at the payment site to ensure that no one pays for a Membership without your permission. By making a payment for Membership to our site, you acknowledge that you have read and agree to the above No Refund and no cancellation Policy.
          </p>
  
          <p className="mb-4">
          We do not offer refunds on subscriptions that have already been taken. The movements in stock market are not always predictable and therefore, Make sure you have gone through the disclaimer, terms and conditions and refund policy before proceeding for subscription or making payment for any of our services.
          </p>
          
        </div>
      </div>
     );
}

export default RefundPolicy;