import offerDetails from '../../data/offer.js';
import OfferCard from "./OfferCard";

const WhatWeOffer = () => {
    return (
        <div className="container mx-auto p-4">
            <h1 className="text-green-700 text-4xl font-bold text-center py-3">WHAT WE OFFER</h1>

            {/* Card container */}
            <div className="flex max-w-full flex-wrap mx-auto justify-center gap-4 lg:mx-[10%]">
                {
                    offerDetails.map((offer, index) => (
                        <OfferCard key={index} offer={offer} />
                    ))
                }
            </div>
        </div>
    );
}

export default WhatWeOffer;
