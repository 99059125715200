
import ContactForm from "../components/contact/ContactForm";
import ContactHero from "../components/contact/ContactHero";
import { FormProvider } from "../store/FormContextApi";

const Contact = () => {
    return(
        <>
            <ContactHero />
            <FormProvider>
                <ContactForm />
            </FormProvider>
        </>
    )
}

export default Contact;