export const algoPrice = [
    {
        title:'Monthly',
        price: "₹ 9,999 + GST",
        features:[
            "Completely Automated",
            "Trade Indices",
            "1 Strategy Coding",
            "WhatsApp Support",
            "Remote Support",
        ],
    },
    {
        title:'Quarterly',
        price: "₹ 25,999 + GST",
        features:[
            "Completely Automated",
            "Trade Indices",
            "3 Strategy Coding",
            "WhatsApp Support",
            "Remote Support",
        ],
    },
    {
        title:'Half Yearly',
        price: "₹ 49,999 + GST",
        features:[
            "Completely Automated",
            "Trade Indices",
            "5-6 Strategy Coding",
            "WhatsApp Support",
            "Remote Support",
        ],
    }
];


export const itPrice = [
    {
        title:'Digital Marketing',
        price: "₹ 34,999 + GST",
        features:[
            "Search Engine Optimization",
            "Social Media Marketing",
            "Youtube Marketing",
            "Pay Per click Service",
            "Remote Support",
        ],
    },
    {
        title:'Web Develoment',
        price: "₹ 29,999 + GST",
        features:[
            "Web Development",
            "Web Designing",
            "Software Development",
            "Optimized Content",
            "Search Engine Optimization",
        ],
    },
    {
        title:'Mobile App Development',
        price: "₹ 59,999 + GST",
        features:[
            "Android App Development",
            "IOS App Development",
            "Incorporate Search Feature",
            "Cross-Plateform Coverage",
            "Social Media Integration",
        ],
    }
];
