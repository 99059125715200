import heroImg from '../images/sevicesPageImage/apiTop.jpg';
import apiBridgeImg from '../images/sevicesPageImage/api-bridge.jpg';

const ApiBridge = () => {
  return (
    <>
      {/* Hero Section */}
      <section className="relative w-full h-fit">
        <img
          className="absolute inset-0 object-cover w-full h-full"
          src={heroImg}
          alt="Hero"
          loading="lazy"
        />

        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[30vh] lg:items-center lg:px-8">
          <div className="max-w-full text-center">
            <span className="font-extrabold text-white sm:text-4xl md:text-4xl lg:text-5xl">
              API BRIDGE
            </span>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <div className="p-6 xl:px-[300px] lg:px[200px] bg-gray-50 text-gray-800">
        {/* Container for content and image */}
        <div className="md:flex md:space-x-8">

          {/* Text Content */}
          <div className="md:w-2/3">
            <h1 className="text-2xl font-bold mb-4">API Bridge</h1>
            <p className="mb-6">
              API Bridge is a set of programming interfaces that integrates your
              trading account with third-party algorithm trading platforms. This
              way, the API Bridge permits you to do algo-trade with different
              stages like Trading Account, MT4, TradingView, Python, Excel,
              NinjaTrader, and others.
            </p>

          </div>

          {/* Image Section - Displayed above on small screens */}
          <div className="md:w-1/3 md:flex-shrink-0 mb-6 md:mb-0">
            <img
              className="rounded-lg shadow-md object-cover w-full h-auto"
              src={apiBridgeImg}
              alt="API Bridge"
              loading="lazy"
            />
          </div>
        </div>
        <div className="mt-8">
            
        <h2 className="text-xl font-semibold mb-4">Features of API Bridge</h2>
            <ul className="list-disc list-inside space-y-2 mb-6">
              <li>
                Coordination with stages like Trading Account, Excel, Python, MT4,
                and others.
              </li>
              <li>
                Compatible with brokers like Fyers, Alice Blue, Zerodha, Upstox,
                Nirmal Bang, Interactive Brokers, IIFL, 5paisa, GRD, and Composite
                Edge.
              </li>
              <li>
                Allows trading for multiple strategies, in different time frames,
                and across different client accounts in various financial markets.
              </li>
              <li>Permits multi-order types like L, M, SL-L, SL-M, BO, and CO.</li>
              <li>
                Sends customized request amounts based on your risk management
                rules for every trade.
              </li>
              <li>
                Selects multi-strikes and multi-expiry contracts through your code
                for options trading.
              </li>
              <li>
                Provides complete diagrams or simple outlines through a scanner
                for your trades.
              </li>
              <li>Connects seamlessly without VPS to many virtual world platforms.</li>
              <li>
                Provides custom targets, SL, time-sensitive signals, and similar
                triggers for your code.
              </li>
              <li>
                Supports a free pattern of Long Entry, Long Exit, Short Entry, and
                Short Exit signals.
              </li>
            </ul>
        </div>
        {/* Rest of the content */}
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">How does API Bridge work?</h2>
          <p className="mb-4">
            The API Bridge works on the basis of “signals”. The signals
            essentially give instructions to buy or sell depending on the
            conditions set. The bridge forms 4 types of signals:
          </p>

          <ul className="list-decimal list-inside pl-4 space-y-2 mb-6">
            <li>
              <strong>Long Entry (LE)</strong>: A new buy to make a long
              position.
            </li>
            <li>
              <strong>Long Exit (LX)</strong>: A sell action to decrease/square
              off the long position.
            </li>
            <li>
              <strong>Short Entry (SE)</strong>: A new buy to make a short
              position.
            </li>
            <li>
              <strong>Short Exit (SX)</strong>: A sell action to decrease/square
              off the short position.
            </li>
          </ul>

          <h2 className="text-xl font-semibold mb-4">The Sequence of Signal Handling:</h2>
          <ol className="list-decimal list-inside pl-4 space-y-2 mb-6">
            <li>A signal is received by the Bridge.</li>
            <li>
              The Symbol Settings Window coordinates with the image given in the
              Signal and the “Input Symbol”.
            </li>
            <li>
              Signal Setting rules are applied from Application Settings to
              Signal Settings.
            </li>
            <li>
              Risk Management rules are applied from Application Settings to
              Global Risk Management.
            </li>
            <li>The request is sent to the API.</li>
          </ol>

          <h2 className="text-xl font-semibold mb-4">Symbol Settings Window</h2>
          <p className="mb-6">
            For giving orders, the API Bridge requires values either from
            signals or from the Symbol Settings Window. Any values provided in
            the Symbol Settings Window override the Signal values.
          </p>
        </div>
      </div>
    </>
  );
};

export default ApiBridge;
