import twentyfour from "../../images/characteristicImage/24x7.png"
import certified from "../../images/characteristicImage/certified.png"
import quality from "../../images/characteristicImage/quality.png"
import price from "../../images/characteristicImage/reasonalble-price.png"

const characteristics = () => {
    return(
        <div className="flex flex-col" >
            <h1 className="text-green-700 text-4xl font-bold text-center py-3">WHY DECODIEFY TECHNOLOGIES</h1>
            <div className="flex flex-wrap justify-evenly py-3">
                <div className="flex flex-col items-center">
                    <img src={twentyfour} alt="24 X 7 Service" className="h-36 w-36 hover:scale-105 transition-transform duration-500 ease-in-out" loading="lazy"/>
                    <h1 className="text-lg font-bold text-green-700">24 X 7 Service</h1>
                </div>
                <div className="flex flex-col items-center">
                    <img src={price} alt="Reasonable Price " className="h-36 w-36 hover:scale-105 transition-transform duration-500 ease-in-out " loading="lazy"/>
                    <h1 className="text-lg font-bold text-green-700">Reasonable Price</h1>
                </div>
                <div className="flex flex-col items-center">
                    <img src={quality} alt="Quality Service" className="h-36 w-36 hover:scale-105 transition-transform duration-500 ease-in-out" loading="lazy"/>
                    <h1 className="text-lg font-bold text-green-700">Quality Service</h1>
                </div>
                <div className="flex flex-col items-center">
                    <img src={certified} alt="ISO Certified" className="h-36 w-36 hover:scale-105 transition-transform duration-500 ease-in-out" loading="lazy"/>
                    <h1 className="text-lg font-bold text-green-700">ISO Certified</h1>
                </div>
            </div>
        </div>
    );
}

export default characteristics;