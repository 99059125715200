import AboutContent from "../components/about/AboutContent";
import AboutHero from "../components/about/AboutHero";
import VisionMission from "../components/about/VisionMission";

const About = () => {
    return(
        <>
            <AboutHero />
            <AboutContent />
            <VisionMission />
        </>
    )
}

export default About;