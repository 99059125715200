import heroImg from '../images/sevicesPageImage/itTop.jpg';
import itImg from '../images/sevicesPageImage/it.jpg';

const ITServices = () => {
    return (
        <>
          {/* Hero Section */}
          <section className="relative w-full h-fit">
            <img
              className="absolute inset-0 object-cover w-full h-full"
              src={heroImg}
              alt="Hero"
              loading="lazy"
            />
    
            {/* Overlay */}
            <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>
    
            <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[30vh] lg:items-center lg:px-8">
              <div className="max-w-full text-center">
                <span className="font-extrabold text-white sm:text-4xl md:text-4xl lg:text-5xl">
                  IT Services
                </span>
              </div>
            </div>
          </section>
    
          {/* Content Section */}
          <div className="p-6 xl:px-[300px] lg:px[200px] bg-gray-50 text-gray-800">
            {/* Container for content and image */}
            <div className="md:flex md:space-x-8">
    
              {/* Text Content */}
              <div className="md:w-2/3">
                <h1 className="text-2xl font-bold mb-4">IT Services Overview</h1>
                <p className="mb-6">
                  IT services encompass a wide range of solutions designed to support businesses in managing and optimizing their technology infrastructure. These services include network management, cybersecurity, cloud computing, software development, and technical support. By leveraging IT services, companies can improve efficiency, enhance data security, and streamline their operations.
                </p>
                <p className="mb-6">
                  Whether it's maintaining critical systems, developing custom applications, or ensuring compliance with industry standards, IT services play a vital role in enabling businesses to stay competitive, reduce downtime, and adapt to rapidly changing technological landscapes.
                </p>
              </div>
    
              {/* Image Section - Displayed above on small screens */}
              <div className="md:w-1/3 md:flex-shrink-0 mb-6 md:mb-0">
                <img
                  className="rounded-lg shadow-md object-cover w-full h-auto"
                  src={itImg}
                  alt="IT Services"
                  loading="lazy"
                />
              </div>
            </div>
    
            {/* Digital Marketing Section */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Digital Marketing</h2>
              <p className="mb-6">
                Our team provides digital marketing strategies highly focused on improving the visibility and reach of your business. Get benefited with our range of digital marketing services, including:
              </p>
              <ul className="list-disc list-inside space-y-2 mb-6">
                <li>Search Engine Optimization (SEO)</li>
                <li>Social Media Optimization (SMO)</li>
                <li>Online Lead Generation</li>
                <li>YouTube SEO</li>
                <li>Online Reputation Management</li>
                <li>PPC Services</li>
              </ul>
            </div>
    
            {/* Web Design and Development Section */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Web Design and Development</h2>
              <p className="mb-6">
                We design and build fresh, dynamic websites for organizations of all sizes. Combining years of experience with the latest technologies, we ensure that our online business strategy is highly effective and tightly integrates with your day-to-day processes.
              </p>
              <p className="mb-6">
                When you commission a website from us, it comes with an inbuilt, multilingual Content Management System (CMS). All you need to manage your website content or menu structure is an internet connection, a web browser, and a basic understanding of word processing.
              </p>
            </div>
    
            {/* Mobile App Development Section */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Mobile App Development</h2>
              <p className="mb-6">
                Mobile app development involves designing and creating software applications for mobile devices such as smartphones and tablets. We handle every stage of the process—from conceptualizing the app's purpose and features to designing the user interface, coding, testing, and deployment. Our apps are optimized for both iOS and Android platforms, ensuring compatibility and smooth user experiences.
              </p>
            </div>
    
            {/* Web Hosting Section */}
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Web Hosting</h2>
              <p className="mb-6">
                Website hosting is an economical solution that enables you to establish your business's global presence. With our web hosting services, your business can showcase products and services to potential customers worldwide. Our web hosting includes:
              </p>
              <ul className="list-disc list-inside space-y-2 mb-6">
                <li>Linux / Windows hosting platform with unlimited sub-domains capability</li>
                <li>Support for popular web technologies & databases</li>
                <li>Manage your website with an easy-to-use online control panel or FTP access</li>
                <li>Web analytics, reporting & backups</li>
                <li>High availability & security</li>
                <li>24x7 technical support</li>
                <li>Support for PHP 4.2.3, ASP, .NET 2.0, Active Perl 5.61</li>
                <li>Free MySQL & MS Access database</li>
                <li>Free online management console</li>
                <li>FTP access and web analytics</li>
              </ul>
            </div>
          </div>
        </>
      );
}

export default ITServices;
