import graph from "../../videos/graph.mp4";

const Hero = () => {
  return (
    <section className="relative w-full h-fit">
      <video
        className="absolute inset-0 object-cover w-full h-full"
        src={graph}
        autoPlay
        loop
        muted
        playsInline
        preload="none"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

      <div className="relative mx-auto max-w-full px-4 py-16 sm:px-6 lg:flex lg:h-[70vh] lg:items-center lg:px-8">
        <div className="max-w-full text-center sm:max-w-full lg:max-w-full lg:text-left">

          <span className="font-extrabold text-white sm:text-5xl md:text-4xl lg:text-5xl">
            <span className="font-extrabold text-green-700">100%</span> Automated Trading Custom Algorithm Development
          </span>
        <br />
        <br />
        <br />
          <h3 className="mt-4 text-base text-white sm:text-lg md:text-xl lg:text-2xl text-left">
            100% Automated API Based Trading System
          </h3>
          <h3 className="mt-4 text-base text-white sm:text-lg md:text-xl lg:text-2xl text-left">
            Software Deployed on AWS Servers
          </h3>
          <h3 className="mt-4 text-base text-white sm:text-lg md:text-xl lg:text-2xl text-left">
            Automated Trade Monitoring
          </h3>
          <h3 className="mt-4 text-base text-white sm:text-lg md:text-xl lg:text-2xl text-left">
            Excellent Risk Management
          </h3>
        </div>
      </div>

    </section>
  );
};

export default Hero;
