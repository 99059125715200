import ServiceHero from "../components/service/ServiceHero";
import ServicesArea from "../components/service/ServicesArea";

const Services = () => {
    return(
        <>
        <ServiceHero />
        <ServicesArea />
        </>
    );
}

export default Services;