import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import whatsImg from "../images/homeImage/whatsapp.jpg";

const WhatsAppWidget = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleWidget = () => {
    setIsOpen(!isOpen);
  };

  const whatsappMessage = () => {
    const phoneNumber = "919718398160";
    const message = "Hello sir! I am interested.\n Please provide more information about advanced algorithm software. ";
    
    const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
    
    window.open(url, "_blank");
  };

  return (
    <>
      {/* WhatsApp Icon Floating Button */}
      <div className="fixed bottom-8 right-5 z-50 hover:scale-125 transition-transform duration-500 ease-in-out">
        <button
          onClick={toggleWidget}
          className="bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600 "
        >
          <FaWhatsapp size={30} />
        </button>
      </div>

      {/* WhatsApp Widget */}
      {isOpen && (
        <div
          className="fixed bottom-24 right-5 max-w-xs bg-white rounded-lg shadow-lg z-50"
          style={{
            backgroundImage: `url(${whatsImg})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Header Section */}
          <div className="bg-green-500 text-white p-4 rounded-t-lg">
            <button
              className="absolute top-2 right-2 text-white hover:text-green-700 text-2xl"
              onClick={toggleWidget}
            >
              &times;
            </button>
            <h3 className="text-lg font-semibold">Decodiefy Technologies</h3>
            <p className="text-sm opacity-90">Typically replies within minutes</p>
          </div>

          {/* Message Box */}
          <div className="p-4 bg-lime-200 bg-opacity-80 m-2 mr-10">
            <p className="text-gray-900 text-sm">
              Any questions related to Trade then contact Immediately?
            </p>
          </div>

          {/* WhatsApp Button */}
          <div className="p-4 pb-2 rounded-lg text-center bg-white mt-20">
            <button
              className="bg-green-500 text-white py-2 px-4 rounded-full flex items-center justify-center w-full hover:bg-green-600"
              onClick={whatsappMessage}
            >
              <FaWhatsapp size={24} className="mr-2" />
              WhatsApp Us
            </button>
            <div className="flex text-center justify-center items-center mt-2 text-[12px]">
              <span className="inline-block w-2 h-2 bg-green-500 rounded-full mr-1"></span>
              Online | Privacy policy
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WhatsAppWidget;
