import {Link} from 'react-router-dom';  

const OfferCard = ({ offer }) => {
    if (!offer) {
        return <div></div>;
    }

    const truncatedDescription = offer.description.length > 200
        ? offer.description.substring(0, 150) + '...'
        : offer.description;

    return (
        <div className="w-full xl:w-[30%] sm:w-[48%] md:w-[42%] m-2 rounded-xl bg-white text-gray-700 shadow-lg hover:scale-105 transition-transform duration-500 ease-in-out">
            <div className="relative overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500">
                <img
                    src={offer.image}
                    alt={offer.title}
                    className="w-full h-[200px] object-cover"
                    loading="lazy"
                />
                <div className="absolute inset-0 w-full h-full bg-gradient-to-tr from-transparent via-transparent to-black/60"></div>
            </div>
            <div className="p-6">
                <h5 className="text-xl font-bold text-[#092638] mb-3 text-center">
                    {offer.title}
                </h5>
                <p className="text-base font-light text-gray-700">
                    {truncatedDescription}
                </p>
            </div>
            <div className="p-6 pt-3">
                <Link
                    to={offer.url}
                    className="block w-full rounded-lg bg-[#092638] py-3.5 px-7 text-center text-sm font-bold uppercase text-white hover:bg-slate-700 hover:text-green-500"
                    type="button"
                >
                    Learn More
                </Link>
            </div>
        </div>
    );
};

export default OfferCard;
