import priceimg from "../../images/heroImage/pricinghero.jpg";

const PricingHero = () => {
  return (
    <section className="relative w-full h-fit">
      <img
        className="absolute inset-0 object-cover w-full h-full"
        src={priceimg}
        loading="lazy"
      />

      {/* Overlay */}
      <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

      <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[65vh] lg:items-center lg:px-8">
        <div className="max-w-full text-center">
          <span className="font-extrabold text-green-500 sm:text-5xl md:text-4xl lg:text-6xl">Pricing
          </span>
        </div>
      </div>

    </section>
  );
};

export default PricingHero;
