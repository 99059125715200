import aboutImg from '../../images/aboutt.jpg'

const AboutContent = () =>{
    return(
        <div class="relative flex flex-col items-center mx-auto lg:flex-row-reverse lg:max-w-5xl lg:mt-0 xl:max-w-6xl lg:mb-0 md:mb-[250px]">

            <div class="w-full h-52 lg:w-1/2 lg:h-auto">
                <img class="h-full w-full object-cover" src={aboutImg} alt="About us" loading="lazy" />
            </div>
            <div
                class="max-w-lg bg-white md:max-w-2xl md:z-10 md:shadow-lg md:absolute md:top-0 md:mt-28 lg:w-3/5 lg:left-0 lg:mt-20 lg:ml-20 xl:mt-24 xl:ml-12">
                <div class="flex flex-col p-12 md:px-16 bg-gray-100">
                    <h2 class="text-2xl font-medium uppercase text-green-700 lg:text-4xl">About<br/> <span className="font-bold">Decodiefy Technologies</span></h2>
                    <p class="mt-4">
                    We are a group of passionate individuals who work tirelessly round the clock to provide consistent access to market participants through secure, simple yet innovative solutions. With the help of our professional team who have a good knowledge of trading principles and a deep understanding of financial calculations, basic statistics, and computational trade execution measurements, provide custom-made trading software and programming strategies to our clients for their hassle-free trade.
                    </p>
                </div>
            </div>

        </div>
    );
}

export default AboutContent;