

const DisclaimerAll = () => {
    return (
      <section className="relative h-8 overflow-hidden">
        <div className="absolute w-full h-full flex items-center">
          {/* Scrolling text */}
          <div className="disclaimer-text text-red-600 text-md whitespace-nowrap">
          Disclaimer- We are not a SEBI registered Investment Advisor nor we provide any kind of tips or recommendations for investments || Consult your investment advisor before using our platform || Market participants shall use our platform for knowledge purpose || Decodiefy Technologies(Prop) is not liable for your profits or losses in any circumstances/conditions || Our service is solely restricted to automated trading application development, deployment and maintenance 
          </div>
        </div>
      </section>
    );
  };
  
  export default DisclaimerAll;
  