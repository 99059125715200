import { FaLock, FaChartLine, FaLaptopCode, FaRobot } from 'react-icons/fa';
import { AiFillApi } from "react-icons/ai";
import apibrideImg from '../../images/servicesImage/apibridge.jpg';
import algotradingImg from '../../images/servicesImage/algotrading.jpg';
import autobuyImg from '../../images/servicesImage/autobuy.jpg';
import strategyImg from '../../images/servicesImage/strategy.jpeg';
import itservicesImg from '../../images/servicesImage/itservices.jpg';

const ServicesArea = () => {
    return (
        <div className="bg-grey-200 pb-16 my-20 space-y-16">
            
            {/* API Bridge */}
            <div className="px-4 flex flex-col md:flex-row items-center justify-around space-y-4 sm:space-y-0">
                <div className="mt-8 xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    {/* text area */}
                    <div>
                        {/* icon */}
                        <div>
                            <span className="flex h-10 w-10 items-center justify-center rounded-xl bg-green-700">
                                <AiFillApi className="h-6 w-6 text-white" />
                            </span>
                        </div>
                        {/* text */}
                        <div className="mt-4">
                            <h2 className="text-2xl font-bold tracking-tight text-green-700">
                                API Bridge:
                            </h2>
                            <p className="mt-2 text-lg text-black">
                                API bridges play a vital role in connecting algorithmic trading software with the trading infrastructure, allowing for automated trading, real-time data analysis, and efficient execution of trading strategies. They enhance the speed, accuracy, and reliability of trading operations, enabling traders to take advantage of market opportunities effectively.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex rounded-lg bg-green-700 px-3 py-1 text-base font-semibold leading-6 text-white shadow-sm ring-1 ring-green-700 hover:bg-[#092638] hover:ring-[#092638]"
                                    href="/services/api-bridge">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 xl:w-[30%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    <div>
                        <img loading="lazy" width="647" height="486" alt="API Bridge"
                            className="rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 hover:shadow-xl hover:shadow-[#1A1D2B]"
                            style={{ color: 'transparent' }}
                            src={apibrideImg} />
                    </div>
                </div>
            </div>

            {/* Algo Trading */}
            <div className="px-4 flex flex-col md:flex-row items-center justify-around space-y-4 sm:space-y-0">
                <div className="mt-8 xl:w-[30%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    <div>
                        <img loading="lazy" width="647" height="486" alt="Algo Trading"
                            className="rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 hover:shadow-xl hover:shadow-[#1A1D2B]"
                            style={{ color: 'transparent' }}
                            src={algotradingImg} />
                    </div>
                </div>

                <div className="mt-8 xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    {/* text area */}
                    <div>
                        {/* icon */}
                        <div>
                            <span className="flex h-10 w-10 items-center justify-center rounded-xl bg-green-700">
                                <FaChartLine className="h-6 w-6 text-white" />
                            </span>
                        </div>
                        {/* text */}
                        <div className="mt-4">
                            <h2 className="text-2xl font-bold tracking-tight text-green-700">
                                Algo Trading:
                            </h2>
                            <p className="mt-2 text-lg text-black">
                                Algorithmic trading, also known as Algo trading or automated trading, is a method of executing trades in financial markets using computer algorithms. It involves the use of pre-defined sets of rules and instructions to automatically place trades, manage positions, and execute orders without the need for human intervention.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex rounded-lg bg-green-700 px-3 py-1 text-base font-semibold leading-6 text-white shadow-sm ring-1 ring-green-700 hover:bg-[#092638] hover:ring-[#092638]"
                                    href="/services/algo-trading">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            {/* Auto Buy & Sell Signals */}
            <div className="py-10 px-4 flex flex-col md:flex-row items-center justify-around space-y-4 sm:space-y-0">
                <div className="mt-8 xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    {/* text area */}
                    <div>
                        {/* icon */}
                        <div>
                            <span className="flex h-10 w-10 items-center justify-center rounded-xl bg-green-700">
                                <FaRobot className="h-6 w-6 text-white" />
                            </span>
                        </div>
                        {/* text */}
                        <div className="mt-4">
                            <h2 className="text-2xl font-bold tracking-tight text-green-700">
                                Auto Buy & Sell Signals:
                            </h2>
                            <p className="mt-2 text-lg text-black">
                                Auto buy and sell signals in the share market refer to automated notifications or indications generated by trading systems or algorithms that suggest when to buy or sell a particular stock or security. These signals are based on predefined rules and technical indicators programmed into the trading system.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex rounded-lg bg-green-700 px-3 py-1 text-base font-semibold leading-6 text-white shadow-sm ring-1 ring-green-700 hover:bg-[#092638] hover:ring-[#092638]"
                                    href="/services/auto-buy-sell-signals">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 xl:w-[30%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    <div>
                        <img loading="lazy" width="647" height="486" alt="Auto Buy & Sell Signals"
                            className="rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 hover:shadow-xl hover:shadow-[#1A1D2B]"
                            style={{ color: 'transparent' }}
                            src={autobuyImg} />
                    </div>
                </div>
            </div>

            {/* Strategy Development */}
            <div className="px-4 flex flex-col md:flex-row items-center justify-around space-y-4 sm:space-y-0">
                <div className="mt-8 xl:w-[30%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    <div>
                        <img loading="lazy" width="647" height="486" alt="Strategy Development"
                            className="rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 hover:shadow-xl hover:shadow-[#1A1D2B]"
                            style={{ color: 'transparent' }}
                            src={strategyImg} />
                    </div>
                </div>

                <div className="mt-8 xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    {/* text area */}
                    <div>
                        {/* icon */}
                        <div>
                            <span className="flex h-10 w-10 items-center justify-center rounded-xl bg-green-700">
                                <FaLock className="h-6 w-6 text-white" />
                            </span>
                        </div>
                        {/* text */}
                        <div className="mt-4">
                            <h2 className="text-2xl font-bold tracking-tight text-green-700">
                                Strategy Development:
                            </h2>
                            <p className="mt-2 text-lg text-black">
                                MT4 and MT5 indicators, Master Advisors, Calculators, and even Money Management formulas are the main Trading tools. Most of these tools are special indicators. These tools have one main objective, to assist brokers with estimating future value changes. We Capital Growth with a group of expert MT4/MT5 software engineers to build up trading strategy.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex rounded-lg bg-green-700 px-3 py-1 text-base font-semibold leading-6 text-white shadow-sm ring-1 ring-green-700 hover:bg-[#092638] hover:ring-[#092638]"
                                    href="/services/strategy-development">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>

            {/* IT Services */}
            <div className="py-10 px-4 flex flex-col md:flex-row items-center justify-around space-y-4 sm:space-y-0">
                <div className="mt-8 xl:w-[40%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    {/* text area */}
                    <div>
                        {/* icon */}
                        <div>
                            <span className="flex h-10 w-10 items-center justify-center rounded-xl bg-green-700">
                                <FaLaptopCode className="h-6 w-6 text-white" />
                            </span>
                        </div>
                        {/* text */}
                        <div className="mt-4">
                            <h2 className="text-2xl font-bold tracking-tight text-green-700">
                                IT Services:
                            </h2>
                            <p className="mt-2 text-lg text-black">
                                IT services deliver essential solutions for businesses to manage and optimize their technology infrastructure. Key offerings like network management, cybersecurity, and software development enhance efficiency and data security, helping companies minimize downtime and adapt to evolving technology landscapes.
                            </p>
                            <div className="mt-4">
                                <a className="inline-flex rounded-lg bg-green-700 px-3 py-1 text-base font-semibold leading-6 text-white shadow-sm ring-1 ring-green-700 hover:bg-[#092638] hover:ring-[#092638]"
                                    href="/services/it">
                                    Learn More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-8 xl:w-[30%] lg:w-[40%] md:w-[100%] sm:w-[100%]">
                    <div>
                        <img loading="lazy" width="647" height="486" alt="IT Services"
                            className="rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 hover:shadow-xl hover:shadow-[#1A1D2B]"
                            style={{ color: 'transparent' }}
                            src={itservicesImg} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesArea;
