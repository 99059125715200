
import {itPrice} from "../../data/pricing.js";
import PricingHero from "./PricingHero.js";
import PricingPlan from './PricingPlan';

const ITPricing = () => {
    return (
        <div className="">
            <PricingHero />
            <section className="bg-gray-100 dark:bg-gray-900 py-[30px]">
                <div className="max-w-screen-lg pb-4 py-8 mx-auto ">
                    <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                        <h2 className="mb-4 text-5xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                            IT SERVICES CHARGE
                        </h2>
                    </div>
                    <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0 px-4">

                        {
                            itPrice.map( (price, index) => (
                                <PricingPlan key={index} price={price} />
                            ))
                        }

                    </div>
                </div>
            </section>
            
            <div className="flex flex-wrap justify-center py-3 text-lg px-4">
                <p>
                <span className="font-bold text-green-700">Note: </span>
                <strong>Three</strong> month and <strong>Six</strong> month services carry maintenance charges.
                </p>
            </div>
        </div>
    );
};

export default ITPricing;
