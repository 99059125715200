import { useState } from 'react';

import Hero from '../components/home/Hero';
import Characteristics from '../components/home/Characteristics';
import AlgoTradeSoftware from '../components/home/AlgoTradeSoftware';
import WhatWeOffer from '../components/home/WhatWeOffer';
import OfferCard from '../components/home/OfferCard';
import ReviewCarousel from '../components/home/ReviewCarousel';
import RequestCall from '../components/home/RequestCall';
import { FormProvider } from '../store/FormContextApi';
import ImageContent from '../components/home/ImageContent';
import DisclaimerAll from '../components/disclaimer/DisclaimerAll';


const Home = () => {

    const [showPopup, setShowPopup] = useState(true);

    const closePopup = () => {
        setShowPopup(false);
    };


    return(
        <>
            <DisclaimerAll />
            <Hero />
            <FormProvider>
                <RequestCall showPopup={showPopup} closePopup={closePopup} />
            </FormProvider>
            <Characteristics />
            <AlgoTradeSoftware />
            <WhatWeOffer />
            <OfferCard />
            <ImageContent />
            <ReviewCarousel />
        </>
    );
}

export default Home;