// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const Login = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     window.location.href = 'https://client.decodiefy.com/login';
//   }, [navigate]);

//   return null;
// };

// export default Login;


import React from 'react';
const Login = () => {
  return (

      <div className="h-[80vh] my-1">
        <iframe
          src="https://client.decodiefy.com/login"
          title="Decodiefy Technologies"
          className="w-full h-full"
          style={{ border: "none" }}
        ></iframe>
      </div>
  );
};

export default Login;
