import { AiOutlineCheck } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const PricingPlan = ({price}) => {
    return (
        <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white hover:bg-green-100">
            <h3 className="mb-4 text-2xl font-bold">{price.title}</h3>
            
            <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-3xl text-green-700 font-extrabold">{price.price}</span>
            </div>

            <ul role="list" className="mb-8 space-y-4 text-left">
                {
                    price.features.map( (feature, index) => (
                        <li className="flex items-center space-x-3" key={index}>
                            <AiOutlineCheck className="w-5 h-5 text-green-500 dark:text-green-400" />
                            <span>{feature}</span>
                        </li>
                    ))
                        
                }
            </ul>
            <Link to="/pricing/payment-details" className="text-white bg-green-700 hover:bg-[#092638] focus:ring-4 focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white dark:focus:ring-red-900">
                Buy Now
            </Link>
        </div>
    );
}

export default PricingPlan;