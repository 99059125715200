import heroImg from '../images/sevicesPageImage/algoTop.jpg';
import algoImg from '../images/sevicesPageImage/algotrading.jpg'; 

const AlgoTrade = () => {
  return (
    <>
      {/* Hero Section */}
      <section className="relative w-full h-fit">
        <img className="absolute inset-0 object-cover w-full h-full" src={heroImg} alt="Hero" loading="lazy" />

        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[30vh] lg:items-center lg:px-8">
          <div className="max-w-full text-center">
            <span className="font-extrabold text-white sm:text-4xl md:text-4xl lg:text-5xl">
              Algo Trade
            </span>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <div className="p-6 xl:px-[300px] lg:px[200px] bg-gray-50 text-gray-800">
        <div className="md:flex md:space-x-8">
          
          {/* Text Content */}
          <div className="md:w-2/3">
            <h1 className="text-2xl font-bold mb-4">Algorithmic Trading</h1>
            <p className="mb-6">
              Algorithmic trading, also known as Algo Trading or automated trading, is a method of
              executing trades in financial markets using computer algorithms. It involves the use
              of pre-defined sets of rules and instructions to automatically place trades, manage
              positions, and execute orders without the need for human intervention.
            </p>
          </div>

          {/* Image Section */}
          <div className="md:w-1/3 md:flex-shrink-0 mb-6 md:mb-0">
            <img className="rounded-lg shadow-md object-cover w-full h-auto" src={algoImg} alt="Algorithmic Trading" loading="lazy" />
          </div>
        </div>
        <div>
        <h2 className="text-xl font-semibold mb-4">Key Features of Algorithmic Trading:</h2>
            <ul className="list-disc list-inside space-y-2 mb-6">
              <li><strong>Automated Trading:</strong> The trading is entirely automated, meaning that the computer program executes trades based on predefined rules and conditions without requiring manual intervention.</li>
              <li><strong>Speed and Efficiency:</strong> Algorithms can analyze market data and execute trades at speeds and frequencies that are impossible for human traders to match, especially in high-frequency trading strategies.</li>
              <li><strong>Market Data Analysis:</strong> Algo trading algorithms use historical and real-time market data to identify patterns, trends, and signals for profitable trading opportunities.</li>
              <li><strong>Execution Strategies:</strong> Strategies like trend-following, mean-reversion, arbitrage, and more, are designed to capitalize on specific market conditions.</li>
              <li><strong>Risk Management:</strong> Algorithms incorporate risk management rules to control position sizes, limit losses, and protect capital.</li>
              <li><strong>Backtesting:</strong> Algorithms are backtested on historical data before live deployment to ensure optimal performance.</li>
              <li><strong>Low Human Bias:</strong> Automation reduces emotional and cognitive biases inherent in human trading.</li>
              <li><strong>Order Slicing:</strong> Algorithms can break large orders into smaller ones to minimize market impact.</li>
              <li><strong>Algorithmic Trading Platforms:</strong> Specialized platforms provided by brokers offer API connectivity for algo trading.</li>
              <li><strong>Regulation and Compliance:</strong> Algorithmic trading is subject to regulatory oversight to ensure market fairness and stability.</li>
            </ul>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">How Does Algorithmic Trading Work?</h2>
          <p className="mb-4">
            Algorithmic trading works by analyzing market data and executing trades based on
            pre-programmed rules or strategies. The system continuously scans for trade signals
            and executes them when the conditions are met, often faster than human traders could
            react. Strategies vary, but they generally include:
          </p>
          <ul className="list-decimal list-inside pl-4 space-y-2 mb-6">
            <li><strong>Trend-Following:</strong> Following market trends based on moving averages or technical indicators.</li>
            <li><strong>Mean-Reversion:</strong> Betting that prices will revert to a mean or average level after deviation.</li>
            <li><strong>Arbitrage:</strong> Exploiting price differences between markets for the same asset.</li>
            <li><strong>Statistical Arbitrage:</strong> Utilizing mathematical models to predict price movements.</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AlgoTrade;
