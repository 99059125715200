import React, { createContext, useState } from 'react';

export const FormContext = createContext();

// Create a provider component
export const FormProvider = ({ children }) => {

    const [requestCallData, setRequestCallData] = useState(null);
    const [contactFormData, setContactFormData] = useState(null);

    // Function to submit request call form data
    const submitRequestCall = async (e) => {
        setRequestCallData(e);
        const data = new FormData(e.target);
        data.append("access_key", "f48daeae-6a79-4a7f-a6f4-bc2b4f168ccc");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: 'POST',
            body: data,
        });

        const finalResponse = await response.json();

        if(finalResponse.success) {
            alert("Call Request Sent\n Our Team will reach you out soon\n Thankyou! \n ");
        } else {
            alert("Something went wrong. Please try again later.");
        }
    };

    // Function to handle contact form submission
    const submitContactForm = async (e) => { 
        setContactFormData(e.target);
        // console.log("Contact form data:", e.target);
        const data = new FormData(e.target);
        data.append("access_key", "f48daeae-6a79-4a7f-a6f4-bc2b4f168ccc");

        const response = await fetch("https://api.web3forms.com/submit", {
            method: 'POST',
            body: data,
        });

        const finalResponse = await response.json();

        if(finalResponse.success) {
            alert("Form Submitted Successfully");
        } else {
            alert("Something went wrong. Please try again later.");
        }
    };

    return (
        <FormContext.Provider value={{
            requestCallData,
            submitRequestCall,
            contactFormData,
            submitContactForm,
        }}>
            {children}
        </FormContext.Provider>
    );
};
