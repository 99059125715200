import { useContext, useState } from 'react';
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt, FaGlobe } from 'react-icons/fa';
import { FormContext } from '../../store/FormContextApi.js';

const ContactForm = () => {
  const { submitContactForm } = useContext(FormContext);

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    submitContactForm(e);   
    setFormData({
      name: '',
      phone: '',
      email: '',
      message: '',
    });
  }

  return (
    <section className="my-10 flex justify-center">
      <div className="container px-6 md:px-12 lg:max-w-[1080px]">
        <div className="block rounded-lg bg-[hsla(0,0%,100%,0.8)] bg-gray-50 px-6 py-12 shadow-lg md:py-16 md:px-12 xl:-mt-[80px] md:mt-[10px] sm:mt[10px] backdrop-blur-[30px] border border-gray-300 justify-between">
          <div className="flex flex-wrap justify-between">
            <div className="mb-12 w-full md:px-3 lg:mb-0 lg:w-6/12 lg:px-6">
            <h1 className='text-green-700 font-bold text-xl mb-10'>Fill the form to contact us</h1>
              <form onSubmit={handleSubmit}>
                {/* Name */}
                <input 
                  type="hidden" 
                  name="subject" 
                  value="Contact Form Submission" />
                <input 
                  type="hidden" 
                  name="subject"
                  value="Contact Request" />
                <div className="relative mb-6">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="nameInput"
                    placeholder="Name"
                  />
                </div>

                {/* Phone */}
                <div className="relative mb-6">
                  <input
                    type="tel"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="phoneInput"
                    placeholder="Mobile Number"
                  />
                </div>

                {/* Email */}
                <div className="relative mb-6">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="emailInput"
                    placeholder="Email address"
                  />
                </div>

                {/* Message */}
                <div className="relative mb-6">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="peer block min-h-[auto] w-full rounded border-2 bg-transparent py-[0.32rem] px-3 leading-[1.6] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary"
                    id="messageInput"
                    placeholder="Message"
                    rows="3"
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="mb-6 w-full rounded bg-green-700 hover:bg-[#092638] text-white px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal lg:mb-0"
                >
                  Send
                </button>
              </form>
            </div>

            {/* Contact details */}
            <div className="w-full lg:w-5/12 lg:ml-2">
              <div className="flex flex-col">
                <div className="mb-12 w-full md:w-full lg:w-full xl:w-full">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md text-2xl bg-green-700 p-4 text-white">
                        <FaPhoneAlt />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold">Technical support</p>
                      <p className="text-sm text-neutral-500">+91 9718398160</p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full md:w-6/12 lg:w-full xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md text-2xl bg-green-700 p-4 text-white">
                        <FaEnvelope />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold">Email</p>
                      <p className="text-sm text-neutral-500">info@decodiefy.com</p>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full md:w-6/12 lg:w-full xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md text-2xl bg-green-700 p-4 text-white">
                        <FaMapMarkerAlt />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold">Address</p>
                      <span className="text-sm text-neutral-500 w-full">Lucknow, Uttar Pradesh</span>
                    </div>
                  </div>
                </div>
                <div className="mb-12 w-full md:w-6/12 lg:w-full xl:w-6/12">
                  <div className="flex items-start">
                    <div className="shrink-0">
                      <div className="inline-block rounded-md text-2xl bg-green-700 p-4 text-white">
                        <FaGlobe />
                      </div>
                    </div>
                    <div className="ml-6 grow">
                      <p className="mb-2 font-bold">Website</p>
                      <p className="text-sm text-neutral-500">https://decodiefy.com/</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
