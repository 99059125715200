const Terms = () => {
    return(
        <div className="bg-gray-100 dark:bg-gray-900">
        <div className="container mx-auto px-4 py-8 lg:px-32 xl:px-48 dark:text-gray-400">
          <h1 className="text-3xl font-bold mb-4 dark:text-gray-200 text-center">Terms & Conditions</h1>
  
          <br />
          <p className="mb-4 font-semibold">
          Dear Client, Welcome to "Decodiefy Technologies".
          </p>
  
          <p className="mb-4">
          Please go through the terms & conditions of our company as per guidelines -
          </p>
        
          <p className="mb-4">
          Please ensure that We are not an Investment Advisor, We are fully into IT solutions and Algo softwares.
          </p>
  
          <p className="mb-4">
          Investment in stock or commodity market is subject to market risk, thus no low risk product is offered, although best attempts are made for predicting market.
          </p>

          <p className="mb-4">
          We do not provide any type of guaranteed, surety and fixed profit commitment Plan.
          </p>

          <p className="mb-4">
          We do not have services related to any type of profit sharing or portfolio management services.
          </p>
  
          <p className="mb-4">
          We suggest you not to work on personal recommendation given by any associates of company. You have paid the service charges in company for software based trading.
          </p>
  
          <p className="mb-4">
          You are not supposed to give your De-mat login id, password to any of our employees. If you do so , neither company nor any of the employees will be responsible for your losses.It will be at your own risk.
          </p>

          <p className="mb-4">
          All the products are updated on website of company, please read all the features before subscribing to any services.
          </p>

          <p className="mb-4">
          Investment Research takes all the necessary measures related to risk and rewards involved in markets before delivering any advice to client but we do not take the responsibility of any kind of losses occurred on trades. We also provide free trial to secure and showcase the pattern, technical languages and accuracy of services on free basis.
          </p>
  
          <p className="mb-4">
          Once the client has paid for the services there will be NO REFUNDS, CANCELLATION or TRANSFERS.
          </p>
  
          <p className="mb-4">
          Kindly ensure yourself by having free trails services prior taking any paid services. Even then we always make sure that we shall provide the most technical tips from our part.
          </p>

          <p className="mb-4">
          We do not have any refund policy at any case. We appeal to our visitors not to offer or allow anyone else even your family members, children, friends to use any electronic modes of payments or your personal accounts for taking subscription without your prior permission.
          </p>

          <p className="mb-4">
          We do not recommend a stock broker. If any stock broker is recommended by any of our representative, we do not receive any consideration by way of remuneration or compensation or in any other form whatsoever from stock broker or any other intermediary so recommended to client.
          </p>

          <p className="mb-4">
          We are not associated with any intermediaries and do not recommend services of any specific intermediaries.
          </p>

          <p className="mb-4">
          The management of company reserves all its right related to any services. The decision of the management of the company shall be final & binding on the customers.
          </p>

          <p className="mb-4">
          In case of clients seeking advice on any specific positions already made by the client, we will be able to suggest best possible action considering our view on the security or product. Such suggestion under any circumstances shall be considered as an opinion (not advice) from our organization and we advice client to consider our opinion and not consultancy to make his/her final decision. We are not liable for any losses whatsoever client may incur in accepting this opinion.
          </p>

          <p className="mb-4">
          Investment is stock or commodity markets is subject to market risk, though best attempts are made for predicting markets, but no surety of return or accuracy of any kind is guaranteed, while the performance sheet of various products is available but should not be considered as a guarantee for future performance of the products/services. Clients are advised to consider all the advice as just an opinion and make investment decision on their own.
          </p>

          <p className="mb-4">In the event of any dispute or difference related to services, the same shall be resolved amicably by mutual conciliation. If the matter is not settled amicably then same shall be referred to the Court of Gomati Nagar jurisdiction. The same shall be resolved amicably by mutual conciliation. If the matter is not settled amicably then same shall be referred to the Court of Gomti Nagar jurisdiction.
            
          </p>
        </div>
      </div>
    );
}

export default Terms;