import { FaEye, FaBullseye } from 'react-icons/fa';

const VisionMission = () => {
    return(
        <section className=" bg-gray-100 py-16 flex justify-center">
            <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
                    <div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                                <FaBullseye className="w-6 h-6" />
                            </div>
                            <h2 className="text-3xl font-extrabold text-[#092638] ml-3">Our Mission</h2>
                        </div>
                        <div className="mt-5">
                            <p className="mt-2 text-base text-gray-500">
                            We aim to apply our IT and algorithm expertise to deliver scalable, customized software solutions that exceed client expectations with secure, efficient, and intuitive products.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="flex items-center">
                            <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-700 text-white">
                                <FaEye className="w-6 h-6" />
                            </div>
                            <h2 className="text-3xl font-extrabold text-[#092638] ml-3">Our Vision</h2>
                        </div>
                        <div className="mt-5">
                            <p className="mt-2 text-base text-gray-500">
                            We aim to build lasting relationships with our clients, providing strong support and integrating technology to optimize operations, drive innovation, and deliver exceptional value.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default VisionMission;
