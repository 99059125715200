const Disclaimer = () => {
    return (
        <div className="bg-gray-100 dark:bg-gray-900">
        <div className="container mx-auto px-4 py-8 lg:px-32 xl:px-48 dark:text-gray-400">
          <h1 className="text-3xl font-bold mb-4 dark:text-gray-200 text-center">Disclaimer</h1>
  
          <br />
          <p className="mb-4">
          All subscription fees paid to Decodiefy Technologies is not refundable. We do not provide trading tips nor we are investment adviser. Our service is solely restricted to automated trading application development, deployment and maintainence. All algorithms are based on backtested data but we do not provide any guarantee for their performace in future.
          </p>
  
          <p className="mb-4">
          All algorithms are based on backtested data but we do not provide any guarantee for their performace in future. The algorithm running in automated system is agreed with user prior deployment and we do not take any liability for any loss generated by the same. Past performance of advise/strategy/model does not indicate the future performance of any current or future strategy/model or advise by Decodiefy Technologies Trades and actual returns may differ significantly from that depicted herein due to various factors including but not limited to impact costs, expense charged, timing of entry/exit, timing of additional flows/redemptions, individual client mandates, specific portfolio construction characteristics etc. There is no assurance or guarantee that the objectives of any strategy/model or advice provided by Decodiefy Technologies Trades will be achieved. Decodiefy Technologies Trades or any of its partner/s or principal officer/employees do not assure/give guarantee for any return on the investment in strategies/models/advise given to the Investor. The value of investment can go up/down depending on factors & forces affecting securities markets. Decodiefy Technologies Trade Trades or its associates are not liable or responsible for any loss or shortfall arising from operations and affected by the market condition.
          </p>
        
        </div>
      </div>
    )
}

export default Disclaimer;