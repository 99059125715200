import React, { useContext, useState } from 'react';

import { FormContext } from '../../store/FormContextApi.js';
const RequestCall = ({ showPopup, closePopup }) => {
    
    const { submitRequestCall } = useContext(FormContext);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        // Prevent default form submission as we'll handle it through an API call
        e.preventDefault();
        submitRequestCall(e);
        // Reset form fields after submission
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
        });
        closePopup();
    };

    if (!showPopup) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-gray-900 text-white p-8 rounded-lg relative w-full max-w-md mx-4">
                <button 
                    className="absolute top-2 right-2 text-white hover:text-green-700 text-2xl" 
                    onClick={closePopup}
                >
                    &times;
                </button>
                <h2 className="text-xl font-bold mb-4 text-center">Request a Call</h2>
                <form 
                    onSubmit={handleSubmit}
                    className="space-y-4"
                >
                    <input 
                        type="hidden" 
                        name="subject" 
                        value="Request Call Form Submission" />
                    <input 
                        type="hidden" 
                        name="subject"
                        value="Request Call" />

                    <input 
                        type="text" 
                        name="name" 
                        placeholder="Your Name" 
                        value={formData.name}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-700 rounded bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring focus:ring-green-700"
                        required 
                    />
                    <input 
                        type="email" 
                        name="email" 
                        placeholder="Your Email" 
                        value={formData.email}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-700 rounded bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring focus:ring-green-700"
                        required 
                    />
                    <input 
                        type="tel" 
                        name="phone" 
                        placeholder="Your Phone Number" 
                        value={formData.phone}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-700 rounded bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring focus:ring-green-700"
                        required 
                        pattern="[0-9]{10}"
                        title="Please enter a valid 10-digit phone number"
                    />
                    <textarea 
                        name="message" 
                        placeholder="Your Message" 
                        value={formData.message}
                        onChange={handleChange}
                        className="w-full p-2 border border-gray-700 rounded bg-gray-800 placeholder-gray-400 text-white focus:outline-none focus:ring focus:ring-green-700"
                        required 
                    ></textarea>
                    <button 
                        type="submit" 
                        className="w-full bg-green-700 hover:bg-[#092638] text-white font-bold py-2 rounded focus:outline-none focus:ring-2 focus:ring-green-300"
                    >
                        Request Call Back
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RequestCall;
