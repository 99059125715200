import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import { Outlet } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import WhatsAppWidget from './components/WhatsAppWidget';


function App() {
  return (
    <>
    <ScrollToTop />
      <Navbar />
        <Outlet />
        <WhatsAppWidget />
      <Footer/>
    </>
  );
}

export default App;

