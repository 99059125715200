import heroImg from '../images/sevicesPageImage/buyTop.png';
import autoImg from '../images/sevicesPageImage/auto-buy.jpg'; // Import the API Bridge image

const AutoBuySell = () => {
  return (
    <>
      {/* Hero Section */}
      <section className="relative w-full h-fit">
        <img className="absolute inset-0 object-cover w-full h-full" src={heroImg} alt="Hero" loading="lazy" />

        {/* Overlay */}
        <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>

        <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[30vh] lg:items-center lg:px-8">
          <div className="max-w-full text-center">
            <span className="font-extrabold text-white sm:text-4xl md:text-4xl lg:text-5xl">
              Auto Buy and Sell Signal
            </span>
          </div>
        </div>
      </section>

      {/* Content Section */}
      <div className="p-6 xl:px-[300px] lg:px-[200px] bg-gray-50 text-gray-800">
        <div className="flex flex-col md:flex-row md:space-x-8 md:space-x-reverse">

          {/* Text Content */}
          <div className="md:w-2/3">
            <h1 className="text-2xl font-bold mb-4">Automated Buy/Sell Signal Software</h1>
            <p className="mb-6">
              You could know precisely when to purchase, hold, or sell stocks or commodities. Automated application signal programming software is an exciting and extraordinary tool for brokers, investors, and analysts in financial markets.
            </p>
            <p className="mb-6">
              Our software has a built-in control system that automatically adjusts three trading objectives based on market behavior, with no human intervention. We provide 100% accurate automated buy/sell signal software for trading. This software operates in all sections like MCX, NCDEX, currency, equity, futures, and more.
            </p>
          </div>

          {/* Image Section - Displayed above on small screens and alongside text on large screens */}
          <div className="md:w-1/3 md:flex-shrink-0 mb-6 md:mb-0">
            <img className="rounded-lg shadow-md object-cover w-full h-auto" src={autoImg} alt="Auto Buy/Sell Software" loading="lazy" />
          </div>

        </div>
        <div>
        
            <p className="mb-6">
              Our products utilize Artificial Intelligence to set various parameters for trading across different instruments. One of the best indicators used is the moving average, calculated by averaging the price of a share or commodity over a specific period. With real-time data, you will receive continuous buy/sell signals from Commodity Market (MCX), NSE, and more.
            </p>
            <p className="mb-6">
              Even those with no prior trading experience can use the system to make informed decisions. Here are some benefits of using our trading system:
            </p>
        </div>
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Benefits of Automated Buy/Sell Signal Software</h2>
          <ul className="list-disc list-inside space-y-2 mb-6">
            <li><strong>Easy Customization:</strong> Easy to install and use with simple customization options.</li>
            <li><strong>Popup and Sound Alerts:</strong> Never miss a signal with alerts that help you avoid wasting time buying tips.</li>
            <li><strong>High Accuracy:</strong> Minimize the risk of manual errors by automating trade placements.</li>
            <li><strong>Support:</strong> Get professional support to help you better understand the system and its functionality.</li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default AutoBuySell;
