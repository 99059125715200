const ReviewCard = ({ review }) => {
  return (
    <div className='bg-[#c6c8c9] bg-opacity-10  p-6 h-[320px] w-[250px] rounded-lg hover:border-white transition duration-300 ease-in-out text-center  hover:border-2'>
      {/* Profile Image */}
      <div className="flex justify-center mb-4">
        <img 
          src={review.image} 
          alt={review.name} 
          className="w-24 h-24 border-4 border-green-100 rounded-full object-cover" 
          loading="lazy"
        />
      </div>
      
      {/* Name */}
      <h3 className="text-lg font-semibold text-[#192329]">- {review.name}</h3>

      {/* Review Message */}
      <p className="text-black text-sm italic">
        "{review.message}"
      </p>
    </div>
  );
};

export default ReviewCard;
