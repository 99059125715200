import benefit from '../../images/homeImage/BenefitOfAlgo.png';
import features from '../../images/homeImage/bestFeatures.png';

const ImageContent = () => {
    return(
        <div className="w-full px-4">
            <div className="flex flex-col sm:flex-col md:flex-row justify-evenly items-center mx-auto my-5 max-h-full max-w-full-lg space-y-5 md:space-y-0 md:space-x-4">
                <div className="w-full md:w-[48%] bg-gray-100 rounded-lg shadow-md overflow-hidden hover:scale-95 transition-transform duration-500 ease-in-out">
                    <img src={benefit} alt="Benefit of Algorithm" className="w-full h-auto object-cover mix-blend-darken md:max-h-fit" />
                </div>
                <div className="w-full md:w-[48%] bg-gray-100 rounded-lg shadow-md overflow-hidden hover:scale-95 transition-transform duration-500 ease-in-out">
                    <img src={features} alt="Best Features" className="w-full h-auto object-cover mix-blend-darken md:max-h-fit" />
                </div>
            </div>
        </div>
    )
}

export default ImageContent;