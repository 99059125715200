import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

// import logo from '../../images/logo-removebg-preview.png'
import logo from '../../images/favicon-removebg.png'
// import logo from '../../images/logo.jpg'
// import logo from '../../images/newLogo.png'


const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [isServicesOpen, setServicesOpen] = useState(false);
    const [isPricesOpen, setPricesOpen] = useState(false);

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    // Handle hover for Services
    const handleServicesHover = (isHovering) => {
        setServicesOpen(isHovering);
        if (isHovering) setPricesOpen(false);
    };

    // Handle hover for Prices
    const handlePricesHover = (isHovering) => {
        setPricesOpen(isHovering);
        if (isHovering) setServicesOpen(false);
    };

    // to get the path for making naclink as aacive
    const location = useLocation();
    const [tab, setTab] = useState("/");
    
    useEffect(() => {
        setTab(location.pathname);
      }, [location]);
    

    return (
        <>
        {/* bg-[#092638] */}
            <div className="max-w-[100%] md:max-w-full sm:max-w-full mx-auto shadow-lg p-3 ">
                <nav className="border-gray-200">
                    <div className="container mx-auto flex flex-wrap items-center md:pl-5 justify-between">
                        <Link to="/" className="flex flex-col items-center ">
                            <img className="h-30 mr-3" width="80" height="100" viewBox="0 0 51 70" fill="none" src={logo} alt="Logo" loading="lazy" />
                            {/* <span className="self-center text-2xl font-bold tracking-wider">
                                Decodiefy <span className='self-center text-2xl font-bold text-green-600 md:ml-2'>Technologies</span>
                            </span> */}
                            {/* <span className="self-center text-[12px] sm:text-md text-center uppercase font-bold text-green-800 logo-text">
                                Decodiefy <br/>Technologies
                            </span> */}
                        </Link>
                        <button
                            onClick={toggleMobileMenu}
                            type="button"
                            className="md:hidden ml-3 text-gray-400 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-300 rounded-lg inline-flex items-center justify-center"
                            aria-expanded={isMobileMenuOpen}
                        >
                            {isMobileMenuOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
                        </button>
                        <div className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-auto lg:mr-5`} id="mobile-menu">
                            <ul className="flex-col md:flex-row flex md:space-x-8 mt-4 md:mt-0 text-base md:text-base font-medium">
                                {/* Navigation links */}
                                <li>
                                    <Link
                                        to="/"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 ${tab === '/' ? 'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/about"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 ${tab === '/about' ? 'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                        About Us
                                    </Link>
                                </li>
                                <li
                                    className="relative"
                                    onMouseEnter={() => handleServicesHover(true)}
                                    onMouseLeave={() => handleServicesHover(false)}
                                >
                                    <Link
                                        to="/services"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-0 pr-4 py-2 md:hover:text-green-600 md:p-0 
                                            ${tab === '/services' || 
                                              tab === '/services/api-bridge' || 
                                              tab === '/services/algo-trading' || 
                                              tab === '/services/auto-buy-sell-signal' || 
                                              tab === '/services/strategy-development' || 
                                              tab === '/services/it' ? 
                                              'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                    <button
                                        id="dropdownNavbarLink"
                                        className=" hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 font-medium flex items-center justify-between w-full md:w-auto"
                                    >
                                        
                                            Services
                                            <FaChevronDown className="w-4 h-4 ml-1" />
                                        
                                    </button>
                                    </Link>
                                    {/* Services menu */}
                                    <div className={`${isServicesOpen ? 'block' : 'hidden'} absolute bg-white text-base z-10 list-none divide-y divide-gray-100 rounded shadow my-1 w-44`} id="dropdownNavbar">
                                        <ul className="py-1" aria-labelledby="dropdownLargeButton">
                                            <li>
                                                <Link
                                                    to="/services/api-bridge"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    API Bridge
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/services/algo-trading"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    Algo Trading
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/services/auto-buy-sell-signal"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    Auto Buy & Sell
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/services/strategy-development"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    Strategy Development
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/services/it"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    IT Services
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li
                                    className="relative"
                                    onMouseEnter={() => handlePricesHover(true)}
                                    onMouseLeave={() => handlePricesHover(false)}
                                >
                                    <Link
                                        to="/pricing/algo"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-0 pr-4 py-2 md:hover:text-green-600 md:p-0 
                                            ${tab === '/pricing/it' || 
                                                tab === '/pricing/algo'?
                                            'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                    <button
                                        id="dropdownNavbarLink"
                                        className=" hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 font-medium flex items-center justify-between w-full md:w-auto"
                                    >
                                        Pricing
                                        <FaChevronDown className="w-4 h-4 ml-1" />
                                    </button>
                                    </Link>
                                    {/* Pricing menu */}
                                    <div className={`${isPricesOpen ? 'block' : 'hidden'} absolute bg-white text-base z-10 list-none divide-y divide-gray-100 rounded shadow my-1 w-44`} id="dropdownNavbar">
                                        <ul className="py-1" aria-labelledby="dropdownLargeButton">
                                            <li>
                                                <Link
                                                    to="/pricing/algo"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    Algo Pricing
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="/pricing/it"
                                                    className="text-sm hover:bg-gray-100 text[#092638] block px-4 py-2"
                                                >
                                                    IT Pricing
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li>
                                    <Link
                                        to="/pricing/payment-details"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 ${tab === '/pricing/payment-details' ? 'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                        Payment
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/contact"
                                        className={`text[#092638] hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 block pl-3 pr-4 py-2 md:hover:text-green-600 md:p-0 ${tab === '/contact' ? 'text-green-600' : ''}`}
                                        aria-current="page"
                                    >
                                        Contact
                                    </Link>
                                </li>
                                <li className="flex justify-center">
                                    <Link
                                        to="/login"
                                        className={`text-white text-center bg-[#092638] hover:bg-white hover:text-green-600 border border-green-600 block pl-3 pr-4 py-1 rounded-full md:p-1 md:py-1 md:px-4 transition duration-300 ease-in-out w-fit sm:w-fit
                                            ${tab === '/login' ? 'hidden' : ''}`}
                                    >
                                        Login
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </>
    );
};

export default Navbar;
