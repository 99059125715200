import reviews from "../../data/reviews"; 
import ReviewCard from "./ReviewCard"; 
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ReviewCarousel = () => {
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    dots: true,
    autoplay:true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          dots:false,
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false
        }
      }
    ]
  };
  
  return (
    <>
    <div className="py-4 w-3/4 m-auto hover:cursor-pointer">
      <h1 className="text-green-700 text-4xl font-bold text-center ">
        What Our Customers Say!
      </h1>
      <div className="my-10 mx-auto">
        <Slider {...settings} >
          {reviews.map((review, index) => (
              <ReviewCard key={index} review={review}/>
          ))}
        </Slider>
      </div>
    </div>
    </>
  );
};

export default ReviewCarousel;
