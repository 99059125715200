const PrivacyPolicy = () => {
    return (
      <div className="bg-gray-100 dark:bg-gray-900">
        <div className="container mx-auto px-4 py-8 lg:px-32 xl:px-48 dark:text-gray-400">
          <h1 className="text-3xl font-bold mb-4 dark:text-gray-200 text-center">Privacy Policy</h1>
  
          <br />
          <p className="mb-4 font-semibold">
          Decodiefy Technologies respects and values the ‘Right to Privacy’ of individuals. We are esteemed by the relationship and by becoming our clients you have a promise from our side that we shall remain loyal to all our clients and non-clients whose information resides with us. This Privacy Policy of Decodiefy Technologies applies to the current clients as well as former clients. Below are the word by word credentials of our Privacy Policy:
          </p>
  
          <p className="mb-4">
          This Privacy Policy applies just to our online exercises and is legitimate for guests to our site with respect to the data that they shared as well as gather in https://decodiefy.com. This plan isn’t material to any information collected removed or by methods for channels other than this site.
          </p>
        
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Consent</h2>
  
          <p className="mb-4">
          By utilizing our site, you agree to our Privacy Policy and consent to its terms.
          </p>


          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Information we collect</h2>
  
          <p className="mb-4">
          The personal data that you are approached to give, and the reasons why you are approached to give it, will be clarified to you at the point we request that you give your own data.
          </p>

          <p className="mb-4">
          In the event that you get in touch with us legitimately, we may get extra data about you, for example, your name, email address, telephone number, the substance of the message as well as connections you may send us, and some other data you may decide to give.
          </p>

          <p className="mb-4">
          At the point when you register for an Account, we may request your contact data, including things, for example, name, organization name, address, email address, and phone number.
          </p>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">How we use your information</h2>
  
          <ul className="list-disc list-inside mb-4">
            <li>
                Give, work, and keep up our site.
            </li>
            <li>
                Improve, customize, and grow our site.
            </li>
            <li>
                Understand and investigate how you utilize our Website.
            </li>
            <li>
                Grow new items, services, highlights, and usefulness.
            </li>
            <li>
                Contact with you, either straightforwardly or through one of our accomplices, including for client care, to give you updates and other data identifying with the site, and for marketing purposes.
            </li>
            <li>
                Send you emails.
            </li>
            <li>
                Discover and prevent misrepresentation.
            </li>
          </ul>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Log Files</h2>
  
          <p className="mb-4">
          https://decodiefy.com follows a standard technique of utilizing log documents. All hosting organizations do this and a sort of hosting services’ examination. The data gathered by log documents include internet protocol (IP) addresses, program type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and potentially the number of clicks. The purpose behind the data is for examining patterns, directing the webpage, following clients’ development on the site, and assembling segment data.
          </p>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Third Party Privacy Policies</h2>
  
          <p className="mb-4">
          https://decodiefy.com’s Privacy Policy doesn’t have any significant bearing to different sponsors or sites. In this way, we are encouraging you to consult the individual Privacy Policies of these outsider advertisement servers for progressively nitty gritty data. It might involve their practices and guidelines regarding how to quit some options.
          </p>
          <p className="mb-4">
            You can decide to disable treats through your individual program choices. To know progressively point by point data about cookie management with explicit internet browsers, it tends to be found at the programs’ particular sites. What Are Cookies?
          </p>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">(Do Not Sell My Personal Information)
          </h2>

          <ul className="list-disc list-inside mb-4">
            <li>
            Request that a business that gathers a consumer’s personal information uncover the categories and explicit bits of individual information that a business has gathered about consumers.
            </li>
            <li>
            Request that a business erase any close data about the consumer that a business has gathered.
            </li>
            <li>
            Request that a business that sells a consumer’s close information, not sell the buyer’s close to home information.
            </li>
          </ul>

          <p className="mb-4">
            In the event that you make a request, we have one month to react to you. In the event that you might want to practice any of these rights, it would be ideal if you get in touch with us.
          </p>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">GDPR Data Protection Rights</h2>
  
          <p className="mb-4">
          We might want to ensure you are completely aware of the entirety of your information security rights. Each client is qualified for the following:
          </p>
  
          <ul className="list-disc list-inside mb-4">
          <li><span className="font-semibold">The right to get to –</span>You reserve the option to demand duplicates of your own information. We may charge you a little expense for this service.</li>
          <li><span className="font-semibold">The right to amendment – </span>You reserve the option to demand that we right any data you accept is mistaken. You additionally reserve the right to demand that we complete the data you accept is incomplete.</li>
          <li><span className="font-semibold">The right to eradication – </span>You reserve the option to demand that we delete your own information, under specific conditions.</li>
          <li><span className="font-semibold">The right to confine processing – </span>You reserve the option to demand that we confine the handling of your own information, under specific conditions.</li>
          <li><span className="font-semibold">The right to protest processing – </span>You reserve the option to question our preparing of your own information, under specific conditions.</li>
          <li><span className="font-semibold">The right to information portability – </span>You reserve the option to demand that we move the data that we have gathered to another association, or directly to you, under specific conditions.</li>
        
          </ul>
  
          <p className="mb-4">
          If you make a request, we have one month to react to you. On the off chance that you might want to practice any of these rights, feel free to get in touch with us.
          </p>
  
          <h2 className="text-2xl font-bold mb-2 dark:text-gray-300">Children’s Information</h2>
  
          <p className="mb-4">
          Another part of our priority is including security for kids while utilizing the web. We urge parents and guardians to watch, take part in, as well as screen and guide theifr online movement.
          </p>

          <p className="mb-4">
            https://decodiefy.com doesn’t intentionally gather any Personal Identifiable Information from kids younger than 13. If you believe that your kid gave this sort of data on our site, we urge you to get in touch with us quickly and we will do our best attempts to promptly expel such data from our records.
          </p>
        </div>
      </div>
    );
  }
  
  export default PrivacyPolicy;
  