import qr from '../../images/qr.jpg';

const QRPage = () => {
    return (
        <div className='flex justify-center h-[60vh] items-center'>
            {/* <h1 className='text-xl font-semibold text-red-600'>It Payment details is not visile contact +91 9718398160 or mail to info@decodiefy.com</h1> */}
        </div>

        // <>
        //     <div className='flex flex-col md:flex-row justify-center items-center my-10 md:my-10 mx-4'>
        //         {/* QR Code Section */}
        //         <div className='p-3 pb-6 border-2 bg-white border-gray-300 hover:bg-green-100 rounded-xl shadow-lg transition-transform transform hover:scale-105 md:mr-8'>
        //             <img 
        //                 src={qr} 
        //                 alt="QR Code" 
        //                 className='w-52 h-52 md:w-64 md:h-64 rounded-lg shadow-md object-cover' 
        //             />
        //             <p className='text-center mt-4 text-green-600 text-xl font-bold'>Scan Here</p>
        //         </div>
                
        //         {/* Bank Details Section */}
        //         <div className='mt-6 md:mt-0'>
        //             <h2 className='text-4xl font-bold text-center md:text-left mb-4'>Bank Details</h2>
        //             <div className='bg-gray-100 p-4 rounded-lg shadow'>
        //                 <h4 className='text-lg font-semibold'>
        //                     <strong>Bank Name/Branch: </strong><span>XYZ BANK</span>
        //                 </h4>
        //                 <h4 className='text-lg font-semibold'>
        //                     <strong>IFSC Code: </strong><span>SBI0001234</span>
        //                 </h4>
        //                 <h4 className='text-lg font-semibold'>
        //                     <strong>Account Number: </strong><span>21365489721</span>
        //                 </h4>
        //             </div>
        //         </div>
        //     </div>
        //     <p className='font-bold text-lg text-red-500 text-center p-4 bg-green-100 rounded-md shadow'>
        //         Note: For more information, contact +91 9876543210
        //     </p>
        // </>
    );
};

export default QRPage;
