import heroImg from '../images/sevicesPageImage/strategyTop.jpg';
import strategyImg from '../images/sevicesPageImage/strategy.jpg'; // Import the API Bridge image

const Strategy = () => {
    return (
        <>
          {/* Hero Section */}
          <section className="relative w-full h-fit">
            <img className="absolute inset-0 object-cover w-full h-full" src={heroImg} alt="Hero" />
    
            {/* Overlay */}
            <div className="absolute inset-0 bg-gray-900/75 sm:bg-transparent sm:from-gray-900/95 sm:to-gray-900/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l"></div>
    
            <div className="relative flex justify-center px-4 py-16 sm:px-6 lg:h-[30vh] lg:items-center lg:px-8">
              <div className="max-w-full text-center">
                <span className="font-extrabold text-white sm:text-4xl md:text-4xl lg:text-5xl">
                  Strategy Development
                </span>
              </div>
            </div>
          </section>
    
          {/* Content Section */}
          <div className="p-6 xl:px-[300px] lg:px[200px] bg-gray-50 text-gray-800">
            <div className="md:flex md:space-x-8">
              
              {/* Text Content */}
              <div className="md:w-2/3">
                <h1 className="text-2xl font-bold mb-4">Tools like MT4 and MT5 Indicators</h1>
                <p className="mb-6">
                  Tools like MT4 and MT5 indicators, master advisors, calculators, and even money management formulas are some of the major trading tools that play a crucial role in assisting brokers in estimating future value changes.
                </p>
              </div>
    
              {/* Image Section */}
              <div className="md:w-1/3 md:flex-shrink-0 mb-6 md:mb-0">
                <img className="rounded-lg shadow-md object-cover w-full h-auto" src={strategyImg} alt="MT4 & MT5 Indicators" />
              </div>
            </div>

            <div>
            <h2 className="text-xl font-semibold mb-4">Why Decodiefy Technologies for Strategy and Development?</h2>
                <p className="mb-6">
                  At Decodiefy Technologies, we have a team of MT4/MT5 engineers dedicated to developing your trading strategy. With the rich experience of our team, we deliver Expert Advisors (EAs), indicators, or scripts according to your preferences. This eliminates the need for you to analyze market status manually.
                </p>
                <p className="mb-6">
                  With zero manual interference, you can trade without the fear and panic that often come with market fluctuations. The Expert Advisor makes the entire trading process hassle-free, ensuring smooth and secure trading for dealers and investors.
                </p>
                <p className="mb-6">
                  Additionally, our custom indicators are tailored to specific technical analysis, helping to simplify value movements and generate trade alerts.
                </p>
            </div>
    
            <div className="mt-8">
              <h2 className="text-xl font-semibold mb-4">Benefits of Strategy Development at Decodiefy Technologies</h2>
              <ul className="list-disc list-inside space-y-2 mb-6">
                <li><strong>Auto Trading through EA:</strong> Automated trading through an Expert Advisor saves your time by executing trades according to your strategy.</li>
                <li><strong>Customized Scripts:</strong> At Decodiefy Technologies, we develop personalized scripts tailored to your trading needs.</li>
                <li><strong>MT4 & MT5 Indicators:</strong> We provide MT4 and MT5 indicator programming services that help simplify your trades.</li>
                <li><strong>Security:</strong> We ensure the safety of your transactional details by masking your IP address.</li>
              </ul>
            </div>
          </div>
        </>
      );
}

export default Strategy;
